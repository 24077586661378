import { createSlice } from "@reduxjs/toolkit";

export const pageHeader = createSlice({
  name: "pageHeader",
  initialState: {
    value: 'Home',
  },
  reducers: {
    changePageHeader: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePageHeader } = pageHeader.actions;
export const currentPageHeader = (state) => state.pageHeader.value;
export default pageHeader.reducer;
