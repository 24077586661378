import { createSlice } from "@reduxjs/toolkit";

export const reloadRacoonData = createSlice({
  name: "reloadRacoonData",
  initialState: {
    value: false,
  },
  reducers: {
    changeReloadRacoonData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeReloadRacoonData } = reloadRacoonData.actions;
export const currentReloadRacoonData = (state) => state.reloadRacoonData.value;
export default reloadRacoonData.reducer;
