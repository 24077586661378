import { configureStore } from "@reduxjs/toolkit";
import getLocalData from "./reducers/getLocalData";
import pageHeader from "./reducers/pageHeader";
import loader from "./reducers/loader";
import snackbar from "./reducers/snackbar";
import addColumn from "./reducers/addColumn";
import reloadRacoonData from "./reducers/reloadRacoonData";

export default configureStore({
  reducer: {
    getLocalData: getLocalData,
    pageHeader: pageHeader,
    loader: loader,
    snackbar: snackbar,
    addColumn: addColumn,
    reloadRacoonData: reloadRacoonData,
  },
});
