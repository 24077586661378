import { createTheme } from '@mui/material/styles';

const lightColorPalette = {
  primary: {
    main: 'rgb(238 79 80)',
    100: 'rgb(238, 79, 80, 0.15)',
    200: 'rgb(238, 79, 80, 0.25)',
  },
  white: 'rgb(255, 255, 255)',
  bodyColor: '#F7F7F9',
  bodyInnerColor: '#ffffff',
  textColor: '#636578',
  black: 'rgb(0, 0, 0)',
};

const darkColorPalette = {
  // Define your dark mode colors here
  // Example: primary: { main: 'darkColorValue' }
  primary: {
    main: 'rgb(238 79 80)',
    100: 'rgb(238, 79, 80, 0.15)',
    200: 'rgb(238, 79, 80, 0.25)',
  },
  bodyColor: '#282a42',
  bodyInnerColor: '#30334e',
  white: 'rgb(255, 255, 255)',
  textColor: '#d2d2e8',
  black: 'rgb(0, 0, 0)',
};

const lightTheme = createTheme({
  palette: lightColorPalette,
});

const darkTheme = createTheme({
  palette: darkColorPalette,
});

export { lightTheme, darkTheme };